import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ComponentProps } from 'react';
import { ArgOf } from 'src/interface';

import { CardInputLayout } from '../AccountDetail/components/CardInputLayout';

/**
 * クレジットカード登録の状態関連のドメインロジックを集約する。
 */

export class CreditCardStore {
  constructor() {
    makeAutoObservable(this, {
      cardTransactionStatus: observable,
      setCardTransactionStatus: action,
      preCardValidate: observable,
      setPreCardValidate: action,
      hasPreCardError: computed,
      preCardInputCompleted: computed,
      isUsingDefaultCard: observable,
      setIsUsingDefaultCard: action,
    });
  }

  public isUsingDefaultCard = false;

  public setIsUsingDefaultCard(using: boolean) {
    this.isUsingDefaultCard = using;
  }

  public cardTransactionStatus?: TransactionStatus = undefined;

  public setCardTransactionStatus(status: TransactionStatus) {
    this.cardTransactionStatus = status;
  }

  public preCardValidate?: PreValidate = undefined;

  public setPreCardValidate(validate: PreValidate) {
    this.preCardValidate = validate;
  }

  get preCardInputCompleted() {
    return (
      !!this.preCardValidate?.cardCvc.complete &&
      !!this.preCardValidate.cardExpiry.complete &&
      !!this.preCardValidate.cardNumber.complete &&
      !!this.preCardValidate.cardHolderName.complete
    );
  }

  get hasPreCardError() {
    if (this.preCardInputCompleted) {
      return false;
    }

    return (
      !!this.preCardValidate?.cardCvc.error ||
      this.preCardValidate?.cardCvc.empty ||
      !!this.preCardValidate?.cardExpiry.error ||
      this.preCardValidate?.cardExpiry.empty ||
      !!this.preCardValidate?.cardNumber.error ||
      this.preCardValidate?.cardNumber.empty ||
      !!this.preCardValidate?.cardHolderName.error ||
      this.preCardValidate?.cardHolderName.empty
    );
  }
}

type TransactionStatus = ArgOf<NonNullable<ComponentProps<typeof CardInputLayout>['onStatusChange']>>[0];

type PreValidate = {
  cardNumber: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
  cardExpiry: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
  cardCvc: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
  cardHolderName: {
    empty: boolean;
    complete: boolean;
    error: string;
  };
};
