import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Layout } from '../../components/Layout';
import { Routes } from '../../constants/Routes';
import { AccountConnectPage } from '../../pages/Accounts/AccountConnect';
import { AccountsNewNameInput } from '../../pages/Accounts/AccountsNew/AccountsNewNameInput';
import { AccountsNewNameInputPharmacyComplete } from '../../pages/Accounts/AccountsNew/AccountsNewNameInput/AccountsNewNameInputPharmacy/AccountsNewNameInputPharmacyComplete';
import { AggressiveBotLink } from '../../pages/Accounts/AggressiveBotLink';
import { StartAuthRedirection } from '../../pages/Accounts/AuthRedirection';
import { GoogleCalendarCoopPage } from '../../pages/Accounts/GoogleCalendarCoop';
import { AppleCalendarPage } from '../../pages/AppleCalendar';
import { AttachmentPage } from '../../pages/Attachments';
import { IdpResponsePage } from '../../pages/Auth/IdpResponse';
import { IndividualCheckupReservationFixPage } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationConfirm';
import { IndividualCheckupConfirmSuccess } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationConfirm/IndividualCheckupConfirmSuccess';
import { IndividualCheckupReservationEditPage } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationEdit';
import { IndividualCheckupReservationCompletePage } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationsComplete';
import { IndividualCheckupReservationNewPage } from '../../pages/Checkup/Individual/Reservations/IndividualCheckupReservationsNew';
import { CheckupPreInterviewPostReserveInputPage } from '../../pages/Checkup/PreInterviews/CheckupPreInterviewEdit/PostReserve';
import { CheckupPreInterviewShowPage } from '../../pages/Checkup/PreInterviews/CheckupPreInterviewShow';
import { CheckupReservationEditPage } from '../../pages/Checkup/Reservations/CheckupReservationsEdit';
import { CheckupReservationFixPage } from '../../pages/Checkup/Reservations/CheckupReservationsFix';
import { CheckupReservationNewPage } from '../../pages/Checkup/Reservations/CheckupReservationsNew';
import { CheckupResultBasePage } from '../../pages/CheckupResult/Base';
import { CheckupResultHistoriesPage } from '../../pages/CheckupResult/Histories';
import { CheckupResultSummaryPage } from '../../pages/CheckupResult/Summary';
import { FaqDetailPage } from '../../pages/Faq/FaqDetail';
import { FaqListPage } from '../../pages/Faq/FaqList';
import { InterviewsComplete } from '../../pages/Interviews/InterviewComplete';
import { InterviewsDetailPage } from '../../pages/Interviews/InterviewsDetail';
import { InterviewsNew } from '../../pages/Interviews/InterviewsNew';
import { InterviewUnansweredPage } from '../../pages/Interviews/UnAnswered/InterviewsUnansweredPage';
import { NewsPage } from '../../pages/News';
import { NotFoundPage } from '../../pages/NotFound';
import { Payjp3dSecureFinishPage } from '../../pages/Payjp/Payjp3dSecureFinish';
import { PrivacyPolicyPage } from '../../pages/Policies/Privacy';
import { UsagePolicyPage } from '../../pages/Policies/Usage';
import { PrescriptionsUploadPage } from '../../pages/Prescriptions/PrescriptionsUpload';
import { PrescriptionUploadComplete } from '../../pages/Prescriptions/PrescriptionUploadComplete';
import { ReceiptsPage } from '../../pages/Receipts';
import { ReservationComplete } from '../../pages/Reservations/ReservationComplete';
import { ReservationFirstComplete } from '../../pages/Reservations/ReservationFirstComplete';
import { ReservationNewPage } from '../../pages/Reservations/ReservationNew';
import { OnlineReservationNewPage } from '../../pages/Reservations/ReservationNew/online';
import { ReservationListPage } from '../../pages/Reservations/ReservationsList';
import { RootPage } from '../../pages/Root';
import { TreatmentDetailPage } from '../../pages/Treatments/TreatmentDetail';
import { TreatmentsListPage } from '../../pages/Treatments/TreatmentList';
import { VacanciesDisplayPage } from '../../pages/Vacancies/VacanciesDisplay';
import { WaitingListItemDeleteCompletePage } from '../../pages/WaitingListItems/WaitingListItemDeleteComplete';
import { WaitingListItemDetailPage } from '../../pages/WaitingListItems/WaitingListItemDetail';
import { WaitingListSuccessPage } from '../../pages/WaitingListItems/WaitingListItemSuccess';
import { MyPageStore } from '../../stores/MyPageStore';
import { WindowUtil } from '../../utils/WindowUtil';
import { AccountDetailApp } from './AccountDetail';
import { ReservationDetailApp } from './ReservationDetail';

/**
 * ユーザがログイン済みの時は、このコンポーネントを読み込むこと。
 */
export const Member = () => {
  const myPageStore = useMemo(() => new MyPageStore(), []);
  return (
    <MyPageStore.Context.Provider value={myPageStore}>
      <Switch>
        <Route path={Routes.Paths.EmbeddedVacancies}>
          <VacanciesDisplayPage />
        </Route>
        <Route>
          <Layout>
            <Switch>
              <Route path={Routes.Paths.Root} exact>
                <RootPage />
              </Route>
              <Route path={Routes.Paths.UsagePolicy} exact>
                <UsagePolicyPage />
              </Route>
              <Route path={Routes.Paths.PrivacyPolicy} exact>
                <PrivacyPolicyPage />
              </Route>
              <Route path={Routes.Paths.Payjp3dSecureFinish}>
                <Payjp3dSecureFinishPage />
              </Route>
              <Route path={Routes.Paths.AccountName} exact>
                <AccountsNewNameInput />
              </Route>
              <Route path={Routes.Paths.AccountNamePharmacyComplete} exact>
                <AccountsNewNameInputPharmacyComplete />
              </Route>
              <Route path={Routes.Paths.Reservations} exact>
                <ReservationListPage />
              </Route>
              <Route path={Routes.Paths.ReservationNew} exact>
                <ReservationNewPage />
              </Route>
              <Route path={Routes.Paths.ReservationPreInterviewSuccess}>
                <InterviewsComplete />
              </Route>
              <Route path={Routes.Paths.ReservationPreInterview} exact>
                <InterviewsNew />
              </Route>
              <Route path={Routes.Paths.ReservationPreInterviewShow}>
                <InterviewsDetailPage />
              </Route>
              <Route path={Routes.Paths.ReservationSuccess} exact>
                <ReservationComplete />
              </Route>
              <Route path={Routes.Paths.ReservationFirstSuccess} exact>
                <ReservationFirstComplete />
              </Route>
              <Route path={Routes.Paths.AccountLineConnect}>
                <AccountConnectPage />
              </Route>
              <Route path={Routes.Paths.ReservationNewOnline}>
                <OnlineReservationNewPage />
              </Route>
              <Route path={Routes.Paths.Account}>
                <AccountDetailApp />
              </Route>
              <Route path={Routes.Paths.GoogleCalendarCoopSetting}>
                <GoogleCalendarCoopPage />
              </Route>
              <Route path={Routes.Paths.ReservationShow}>
                <ReservationDetailApp />
              </Route>
              <Route path={Routes.Paths.MedicalHistories} exact>
                <TreatmentsListPage />
              </Route>
              <Route path={Routes.Paths.MedicalHistoryShow}>
                <TreatmentDetailPage />
              </Route>
              <Route path={Routes.Paths.Receipts} exact>
                <ReceiptsPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationNew}>
                <CheckupReservationNewPage />
              </Route>
              <Route path={Routes.Paths.CheckupPreInterviewShow}>
                <CheckupPreInterviewShowPage />
              </Route>
              <Route path={Routes.Paths.CheckupPreInterviewPostReserveEdit}>
                <CheckupPreInterviewPostReserveInputPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationEdit}>
                <CheckupReservationEditPage />
              </Route>
              <Route path={Routes.Paths.Notifications}>
                <NewsPage />
              </Route>
              <Route path={Routes.Paths.IdpResponse}>
                <IdpResponsePage />
              </Route>
              <Route path={Routes.Paths.FaqList} exact>
                <FaqListPage />
              </Route>
              <Route path={Routes.Paths.FaqShow} exact>
                <FaqDetailPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationNewIndividual}>
                <IndividualCheckupReservationNewPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationEditIndividual}>
                <IndividualCheckupReservationEditPage />
              </Route>
              <Route path={Routes.Paths.AuthorizedAttachment}>
                <AttachmentPage />
              </Route>
              <Route path={Routes.Paths.PrescriptionUpload}>
                <PrescriptionsUploadPage />
              </Route>
              <Route path={Routes.Paths.PrescriptionUploadComplete}>
                <PrescriptionUploadComplete />
              </Route>
              <Route path={Routes.Paths.CheckupReservationFix}>
                <CheckupReservationFixPage />
              </Route>
              <Route path={Routes.Paths.UnAnsweredInterview}>
                <InterviewUnansweredPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationCompleteIndividual}>
                <IndividualCheckupReservationCompletePage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationFixIndividual}>
                <IndividualCheckupReservationFixPage />
              </Route>
              <Route path={Routes.Paths.CheckupReservationInterviewCompleteIndividual}>
                <IndividualCheckupConfirmSuccess />
              </Route>
              <Route path={Routes.Paths.CheckupResultHistories}>
                <CheckupResultHistoriesPage />
              </Route>
              <Route path={Routes.Paths.CheckupResultSummary}>
                <CheckupResultSummaryPage />
              </Route>
              <Route path={Routes.Paths.CheckupResultBase}>
                <CheckupResultBasePage />
              </Route>
              <Route path={Routes.Paths.WaitingListItemDeleteComplete}>
                <WaitingListItemDeleteCompletePage />
              </Route>
              <Route path={Routes.Paths.WaitingSuccess}>
                <WaitingListSuccessPage />
              </Route>
              <Route path={Routes.Paths.WaitingListItemShow}>
                <WaitingListItemDetailPage />
              </Route>
              <Route
                path={Routes.Paths.GoogleCalendarURLSchemeRedirect}
                render={() => {
                  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    WindowUtil.openInDefaultBrowser(`com.google.calendar://${window.location.search}`);
                  } else {
                    WindowUtil.openInDefaultBrowser(`https://www.google.com/calendar/render${window.location.search}`);
                  }
                  return (
                    <>
                      Google Calendarアプリにリダイレクトしています。
                      <code>{`https://www.google.com/calendar/render?${window.location.search}`}</code>
                    </>
                  );
                }}
              />
              <Route path={Routes.Paths.StartAuth}>
                <StartAuthRedirection />
              </Route>
              <Route path={Routes.Paths.AggressiveBotLink}>
                <AggressiveBotLink />
              </Route>
              <Route path={Routes.Paths.addToIPhoneCalendar}>
                <AppleCalendarPage />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </MyPageStore.Context.Provider>
  );
};
