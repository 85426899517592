import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Sex } from '../../../../../gql/gql-types';
import { PhoneInput } from '../../../..//components/PhoneInput';
import { Button } from '../../../../components/Button';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Caption } from '../../../../components/Caption';
import { Card } from '../../../../components/Card';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { CheckboxGroup, FormItem, FormLabel, FormLayout, FormSubLabel } from '../../../../components/Form';
import { Radio } from '../../../../components/Radio';
import { TextField } from '../../../../components/TextField';
import { useToast } from '../../../../components/Toast';
import { Wrapper } from '../../../../components/Wrapper';
import { GtmClasses } from '../../../../constants/AnalyticsTags';
import { Dictionary } from '../../../../constants/Dictionaries';
import { Routes } from '../../../../constants/Routes';
import { Color } from '../../../../constants/Style';
import { useContext } from '../../../../hooks/useContext';
import { useTransaction } from '../../../../hooks/useTransaction';
import { AccountDetailStore } from '../stores/AccountDetailStore';
import { AddressInput } from './components/AddressInput';
import { InsuranceCardInput } from './components/InsuranceCardInput';

export const AccountEditPage = observer(() => {
  const store = useContext(AccountDetailStore.Context);
  const { addToast } = useToast();
  const { push } = useHistory();

  const [update, updateStatus] = useTransaction(async () => {
    if (!store.id) return;
    await store.updateProfile();
    addToast({ message: 'プロフィールを更新しました。', icon: 'check-circle' });
    push(Routes.Paths.Account);
  });

  return (
    <Wrapper>
      <DocumentTitle title="プロフィール編集" />
      <Caption type="header" underline align="center">
        プロフィール
      </Caption>
      <FormLayout>
        <FormItem>
          <FormLabel required>氏名</FormLabel>
          <TextField
            required
            color="white"
            value={store.edit?.name ?? ''}
            onChange={v => store.edit?.setName(v)}
            error={store.edit?.errors.some(e => e.field === 'name')}
          />
          {store.edit?.errors.find(e => e.field === 'name') && (
            <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
              {store.edit?.errors.find(e => e.field === 'name')?.error}
            </div>
          )}
        </FormItem>
        <FormItem>
          <FormLabel>生年月日</FormLabel>
          <TextField
            color="white"
            type="date"
            value={store.edit?.birthday}
            onChange={v => store.edit?.setBirthday(v)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>性別</FormLabel>
          <Card>
            <CheckboxGroup>
              <Radio
                label={Dictionary.UserSex[Sex.Male]}
                name="sex"
                value={Sex.Male}
                onChange={v => store.edit?.setSex(v.target.value as Sex)}
                checked={store.edit?.sex === Sex.Male}
              />
              <Radio
                label={Dictionary.UserSex[Sex.Female]}
                name="sex"
                value={Sex.Female}
                onChange={v => store.edit?.setSex(v.target.value as Sex)}
                checked={store.edit?.sex === Sex.Female}
              />
            </CheckboxGroup>
          </Card>
        </FormItem>
        <FormItem>
          <FormLabel>メールアドレス</FormLabel>
          <TextField color="white" type="email" value={store.edit?.mail} onChange={v => store.edit?.setMail(v)} />
        </FormItem>
        <FormItem>
          <FormLabel required>電話番号</FormLabel>
          <PhoneInput
            required
            color="white"
            value={store.edit?.phone}
            onChange={v => store.edit?.setPhone(v)}
            analyticsClassName={GtmClasses.gtm.registration.patientInformation.editForm.phoneNumber}
            error={store.edit?.errors.some(e => e.field === 'phone')}
            errors={store.edit?.errors}
          />
        </FormItem>
        <FormItem>
          <FormLabel>住所</FormLabel>
          <AddressInput
            value={
              store.edit?.address || {
                addressLine1: '',
                addressLine2: '',
                pref: '',
                zipCode: '',
              }
            }
            onChange={v => store.edit?.setAddress(v)}
          />
        </FormItem>
        <FormItem>
          <FormLabel>保険証画像</FormLabel>
          <FormSubLabel>
            保険証表面の写真を撮影して、アップロードしてください。
            <br />
            尚、ご来院当日もご本人確認のため保険証を忘れずにご持参ください。
            <br />
            <span style={{ color: Color.FunctionalRedNeutral }}>
              ※保険証利用登録済みのマイナンバーカードを持参いただく場合は、事前の保険証アップロードは不要です。
            </span>
          </FormSubLabel>
          {store.edit && (
            <Card color={store.edit.insuranceCards.urls.length === 0 ? 'normal' : 'inset'}>
              <InsuranceCardInput store={store.edit.insuranceCards} />
            </Card>
          )}
        </FormItem>
        <ButtonGroup gutter={10}>
          <Button
            block
            disabled={!!updateStatus.running || !store.edit?.valid}
            onClick={() => update()}
            className={GtmClasses.gtm.registration.patientInformation.finishFromMyPage}
          >
            この内容で保存する
          </Button>
        </ButtonGroup>
      </FormLayout>
    </Wrapper>
  );
});
