import { makeAutoObservable, observable } from 'mobx';
import { createContext } from 'react';

export class PayjpStore {
  public static Context = createContext<PayjpStore>(new PayjpStore());

  constructor() {
    makeAutoObservable(this, {
      payjp: observable,
      valid: observable,
      cardHolderName: observable,
      phone: observable,
    });
  }

  public payjp = undefined;
  public valid = true;
  public cardHolderName = '';
  public phone = '';

  public setPayjp(payjp: any) {
    this.payjp = payjp;
  }

  public setValid(valid: boolean) {
    this.valid = valid;
  }

  public setCardHolderName(cardHolderName: string) {
    this.cardHolderName = cardHolderName;
  }

  public setPhone(phone: string) {
    this.phone = phone;
  }
}
