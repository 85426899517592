import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { GQL } from '../../../../../../../gql/client';
import {
  CreateCardInput,
  CreateUserInput,
  CreateWaitingListItemMutation,
  CreateWaitingListItemMutationVariables,
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from '../../../../../../../gql/gql-types';
import { createWaitingListItem } from '../../../../../../../gql/operations/createWaitingListItem';
import { registerUser } from '../../../../../../../gql/operations/registerUser';
import { Card } from '../../../../../../domains/customer/Card';
import { CreditCardStore } from '../../../../store/CreditCardStore';
import { ErrorInfo, Name } from '../../../stores/ProfileStore';

export class GuestWaitingListStore {
  constructor() {
    makeAutoObservable(this, {
      name: observable,
      setName: action,
      valid: observable,
      fix: action,
      userId: observable,
      errors: observable,
      mail: observable,
      setMail: action,
      card: observable,
      setCard: action,
      phone: observable,
      setPhone: action,
    });
  }

  public name?: Name = undefined;
  public mail?: string = undefined;
  public userId?: string = undefined;
  public phone?: string = undefined;
  public valid = false;
  public errors: ErrorInfo[] = [];

  public get fixable() {
    return this.valid;
  }

  public clinic?: string = undefined;
  public department?: string = undefined;
  public treatmentKind?: string = undefined;

  public start: string = new Date().toISOString();
  public end: string = new Date().toISOString();

  public newComer = true;

  public setNewComer(newComer: boolean) {
    this.newComer = newComer;
  }

  public async fix() {
    if (!this.userId) {
      return;
    }

    if (this.newComer) {
      const user: CreateUserInput = {
        id: this.userId,
        // eslint-disable-next-line no-irregular-whitespace
        name: this.name ? `${this.name.sei}　${this.name.mei}` : '',
        mail: this.mail,
        sei: this.name?.sei,
        mei: this.name?.mei,
        confirmed: [],
        insuranceCards: [],
        phone: this.phone || '',
      };

      const card: CreateCardInput | undefined = this.card
        ? {
            id: '_',
            cardCustomerId: '_',
            fourDigit: this.card.fourDigit || '',
            brand: this.card.brand || '',
            payjpID: this.card.id || '',
            expAt: this.card.expAt || '',
          }
        : undefined;

      await GQL.query<RegisterUserMutationVariables, RegisterUserMutation>(registerUser, {
        input: {
          user,
          card,
        },
      });
    }

    await GQL.query<CreateWaitingListItemMutationVariables, CreateWaitingListItemMutation>(createWaitingListItem, {
      input: {
        userId: this.userId,
        clinic: this.clinic,
        department: this.department,
        treatmentKind: this.treatmentKind,
        start: this.start,
        end: this.end,
      },
    });
  }

  public setName(name: Name) {
    this.name = name;
    this.checkNameValidity();
  }

  public setMail(mail: string) {
    this.mail = mail;
  }

  private checkNameValidity() {
    runInAction(() => {
      // 名前関連のエラーをクリア
      this.errors = this.errors.filter(error => error.field !== 'sei' && error.field !== 'mei');

      if (!this.name) {
        this.errors = [...this.errors, { field: 'sei', error: '氏名を入力してください。' }];
        this.valid = false;
        return;
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.sei)) {
        this.errors = [...this.errors, { field: 'sei', error: '氏名は全角カタカナで入力してください。' }];
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.mei)) {
        this.errors = [...this.errors, { field: 'mei', error: '氏名は全角カタカナで入力してください。' }];
      }

      // エラーが残っていればvalidをfalseに設定
      this.valid = this.errors.length === 0;
    });
  }

  private checkPhoneValidity() {
    runInAction(() => {
      // 電話番号関連のエラーをクリア
      this.errors = this.errors.filter(error => error.field !== 'phone');

      if (!this.phone) {
        this.errors = [...this.errors, { field: 'phone', error: '電話番号を入力してください。' }];
        this.valid = false;
        return;
      }

      if (this.phone.startsWith('+81')) {
        const localNumber = this.phone.substring(3);
        if (!/^\d{9,10}$/.test(localNumber)) {
          this.errors = [...this.errors, { field: 'phone', error: '10桁または11桁で入力してください。' }];
        }
      } else {
        if (!/^\+\d{2,}\d{7,11}$/.test(this.phone)) {
          this.errors = [...this.errors, { field: 'phone', error: '電話番号の桁数を確認してください。' }];
        }
      }

      // エラーが残っていればvalidをfalseに設定
      this.valid = this.errors.length === 0;
    });
  }

  public get id() {
    return `${this.clinic}#${this.start}#${this.department}#${this.treatmentKind || this.department}#${this.userId}`;
  }

  public card?: Card = undefined;

  public setCard(card: Card) {
    this.card = card;
  }

  public cardStatus: CreditCardStore = new CreditCardStore();

  public setPhone(phone: string) {
    this.phone = phone;
    this.checkPhoneValidity();
  }
}
