import * as Sentry from '@sentry/browser';
import { ComponentProps, useEffect, useState } from 'react';

import { GQL } from '../../../../../gql/client';
import {
  CreateCardInput,
  GetPayjpPublicKeyQuery,
  GetPayjpPublicKeyQueryVariables,
  ValidateCardMutation,
  ValidateCardMutationVariables,
} from '../../../../../gql/gql-types';
import { getPayjpPublicKey } from '../../../../../gql/operations/getPayjpPublicKey';
import validateCard from '../../../../../gql/operations/validateCard';
import { CardError } from '../../../../constants/CreditCard';
import { BrandIcon } from '../components/BrandIcon';
import { PayjpStore } from '../stores/PayjpStore';

const Payjp = (window as any).Payjp as any;

export const KEYS = ['cardNumber', 'cardExpiry', 'cardCvc'] as const;

type Brands = ComponentProps<typeof BrandIcon>['brand'];

type Submitter = {
  submit?: () => Promise<void>;
};

type CallbackArg = Pick<CreateCardInput, 'brand' | 'expAt' | 'fourDigit' | 'payjpID'>;

export const usePayjp = (inputStyle: InputStyle, callback: (a: CallbackArg) => Promise<void>, store: PayjpStore) => {
  const [focused, setFocused] = useState<string>('');
  const [brand, setBrand] = useState<Brands>('unknown');
  const [onSubmit, setOnSubmit] = useState<Submitter>({});
  const [status, setStatus] = useState({
    running: false,
    error: '',
  });

  const [cardNumberStatus, setCardNumberStatus] = useState({ empty: true, complete: false, error: '' });
  const [cardExpiryStatus, setCardExpiryStatus] = useState({ empty: true, complete: false, error: '' });
  const [cardCvcStatus, setCardCvcStatus] = useState({ empty: true, complete: false, error: '' });
  const [cardHolderNameStatus, setCardHolderNameStatus] = useState({ empty: true, complete: false, error: '' });

  const cardHolderNameChange = () => {
    setCardHolderNameStatus({
      empty: store.cardHolderName === '',
      complete: store.cardHolderName !== '',
      error: store.cardHolderName === '' ? '名義人を入力してください' : '',
    });
  };

  useEffect(() => {
    (async () => {
      const pk = await GQL.query<GetPayjpPublicKeyQueryVariables, GetPayjpPublicKeyQuery>(getPayjpPublicKey, {});
      const { payjp } = store;
      let newPayjp: any;

      // 二回インスタンスを立てようとするとエラーになることの回避

      if (!payjp) {
        // https://js.pay.jp/v2/pay.js が読み込まれていることが前提
        newPayjp = Payjp(pk.getPayjpPublicKey?.publicKey, { threeDSecureWorkflow: 'redirect' }) as any;
        store.setPayjp(newPayjp);
      } else {
        newPayjp = payjp;
      }
      // 参考例 https://pay.jp/docs/payjs-guidance#demo
      const elements = newPayjp.elements();
      const es = KEYS.map(key => ({
        key,
        element: elements.create(key, { style: inputStyle }),
      }));
      es.forEach(({ key, element }) => {
        element.mount(`#${key}`);
        element.on('focus', () => setFocused(key));
        element.on('blur', () => setFocused(''));
      });
      // 参考： https://pay.jp/docs/payjs#:~:text=Event-,element.on(%27change%27%2C%20listener%3A%20function),-Element%20%E3%81%AE%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0
      es[0]?.element.on('change', ({ brand, error, complete, empty }: PayjpEvent) => {
        setBrand(brand);
        setCardNumberStatus({ empty, complete, error: error?.message || '' });
      });
      es[1]?.element.on('change', ({ error, complete, empty }: PayjpEvent) => {
        setCardExpiryStatus({ empty, complete, error: error?.message || '' });
      });
      es[2]?.element.on('change', ({ error, complete, empty }: PayjpEvent) => {
        setCardCvcStatus({ empty, complete, error: error?.message || '' });
      });
      setOnSubmit({
        submit: async () => {
          store.setValid(false);
          setStatus({ running: true, error: '' });
          Sentry.addBreadcrumb({
            category: 'payjp',
            message: `started createToken`,
            level: Sentry.Severity.Info,
          });
          const tokens = await newPayjp.createToken(es[0].element);
          if (tokens.error) {
            Sentry.addBreadcrumb({
              category: 'payjp',
              message: `failed createToken`,
              level: Sentry.Severity.Error,
              data: {
                ...tokens.error,
              },
            });
            setStatus({ running: false, error: tokens.error.message });
            store.setValid(true);
            throw tokens.error;
          }
          Sentry.addBreadcrumb({
            category: 'payjp',
            message: `completed createToken`,
            level: Sentry.Severity.Info,
          });
          const res = await GQL.query<ValidateCardMutationVariables, ValidateCardMutation>(validateCard, {
            input: {
              card: tokens.id,
              clinicId: '01',
            },
          });
          if (res.validateCard?.success) {
            // 3Dセキュア処理待ち状態のトークンを取得
            const otherTokens = await newPayjp.createToken(es[0].element, {
              three_d_secure: true,
              card: {
                name: store.cardHolderName,
                phone: store.phone,
              },
            });
            if (otherTokens.error) {
              Sentry.addBreadcrumb({
                category: 'payjp',
                message: `failed create3dSecureToken`,
                level: Sentry.Severity.Error,
                data: {
                  ...otherTokens.error,
                },
              });
              setStatus({ running: false, error: otherTokens.error.message });
              store.setValid(true);
              throw otherTokens.error;
            }
            Sentry.addBreadcrumb({
              category: 'payjp',
              message: `completed create3dSecureToken`,
              level: Sentry.Severity.Info,
            });
            await callback({
              brand: otherTokens.card.brand,
              expAt: `${otherTokens.card.exp_month}/${String(otherTokens.card.exp_year).slice(-2)}`,
              fourDigit: otherTokens.card.last4,
              payjpID: otherTokens.id,
            });
            setStatus({ running: false, error: '' });
            store.setValid(true);
          } else {
            Sentry.addBreadcrumb({
              category: 'payjp',
              message: `failed confirmation card`,
              level: Sentry.Severity.Error,
              data: {
                ...res.validateCard?.message,
              },
            });
            const code = res.validateCard?.message?.code;
            const key = code as keyof typeof CardError;
            let error = '';
            if (key in CardError) {
              error = CardError[key];
            } else {
              error = CardError['unableCard'];
            }

            setStatus({ running: false, error });
            store.setValid(true);

            throw {
              message: res.validateCard?.message,
            };
          }
        },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    focused,
    brand,
    onSubmit,
    status,
    cardHolderNameChange,
    preValidate: {
      cardNumber: cardNumberStatus,
      cardCvc: cardCvcStatus,
      cardExpiry: cardExpiryStatus,
      cardHolderName: cardHolderNameStatus,
    },
  };
};

// 型情報はこちらを参照
// https://pay.jp/docs/payjs

type InputStyle = {
  base?: React.CSSProperties;
  empty?: React.CSSProperties;
  complete?: React.CSSProperties;
  invalid?: React.CSSProperties;
};

type PayjpEvent = {
  brand: Brands;
  complete: boolean;
  elementType: string;
  empty: boolean;
  error?: {
    message?: string;
    type?: string;
    code?: 'incomplete_error' | 'invalid_number' | 'invalid_expiry_year_past' | 'invalid_expiry_month_past';
  };
};
