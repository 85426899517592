import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { DocumentTitle } from '../../../../components/DocumentTitle';
import { useContext } from '../../../../hooks/useContext';
import { SessionStore } from '../../../../stores/SessionStore';
import { useProfileAction } from '../hooks/useProfile';
import { AccountsNewNameInputCheckup } from './AccountsNewNameInputCheckup';
import { AccountsNewNameInputFix } from './AccountsNewNameInputFix';
import { AccountsNewNameInputCheckupFix } from './AccountsNewNameInputFixCheckupIndividual';
import { AccountsNewNameInputFixWaitingListItem } from './AccountsNewNameInputFixWaitingListItem';
import { AccountsNewNameInputLineConnect } from './AccountsNewNameInputLineConnect';
import { AccountsNewNameInputNormal } from './AccountsNewNameInputNormal';
import { AccountsNewNameInputPharmacy } from './AccountsNewNameInputPharmacy';

export const AccountsNewNameInput = observer(() => {
  const action = useProfileAction();
  const session = useContext(SessionStore.Context);

  useEffect(() => {
    session.fetchUserEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="患者プロフィールの入力" />
      {(() => {
        switch (action) {
          case 'fixTreatment':
            return <AccountsNewNameInputFix />;
          case 'pharmacyRegister':
            return <AccountsNewNameInputPharmacy />;
          case 'startCheckup':
            return <AccountsNewNameInputCheckup />;
          case 'fixCheckupTreatment':
            return <AccountsNewNameInputCheckupFix />;
          case 'connectLine': // FIXME: 2021年07月05日から呼び出しなし。削除する。
            return <AccountsNewNameInputLineConnect />;
          case 'fixWaitingListItem':
            return <AccountsNewNameInputFixWaitingListItem />;
          case 'NONE':
          case 'redirect':
          default:
            return <AccountsNewNameInputNormal />;
        }
      })()}
    </>
  );
});
