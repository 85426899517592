import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { ErrorDialogContext } from '../../../../../components/ErrorDialog';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { PhoneInput } from '../../../../../components/PhoneInput';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { GtmClasses } from '../../../../../constants/AnalyticsTags';
import { Routes } from '../../../../../constants/Routes';
import { useContext } from '../../../../../hooks/useContext';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../stores/SessionStore';
import { CardInputLayout } from '../../../AccountDetail/components/CardInputLayout';
import { Payjp3dSecureStore } from '../../../store/Payjp3dSecureStore';
import { errorStyle } from '../components/AccountsNameInput';
import { NameInput } from '../components/NameInput';
import { CheckupReservationTemporallyStore } from './stores/CheckupReservationTemporallyStore';

export const AccountsNewNameInputCheckupFix = observer(() => {
  const session = useContext(SessionStore.Context);
  const [authStateKnown, setAuthStateKnown] = useState(false);
  const { reservationId, push } = useReservationId();
  const store = useMemo(() => new CheckupReservationTemporallyStore(), []);
  const { setError } = useContext(ErrorDialogContext);
  const [load, loadStatus] = useTransaction(
    () => store.fetch(reservationId),
    e => {
      setError({
        header: '予約に失敗しました',
        message: e.message,
        onOk: () => {
          session.logout('/');
          setError();
        },
      });
      setAuthStateKnown(true);
    },
  );

  const [doFix, fixStatus] = useTransaction(
    async () => {
      const res = await store.fix();
      res && push(Routes.Paths.ReservationSuccess, res);
    },
    e => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gqlError: { errors?: { message: string }[] } = e as any;
      setError({
        header: '予約に失敗しました',
        message: gqlError?.errors?.[0]?.message || e.message,
        onOk: () => {
          session.logout('/');
          setError();
        },
      });
    },
  );

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }

    const user = session.user?.userEntity;
    if (!user) {
      setAuthStateKnown(true);
      store.setNameRequired(true);
    }

    load()
      .then(() => {
        if (!store.confirmRequired) {
          doFix();
        }
        setAuthStateKnown(true);
      })
      .catch(e => {
        setError({
          header: '予約に失敗しました',
          message: e.message,
          onOk: () => {
            session.logout('/');
            setError();
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown]);

  if (!authStateKnown || loadStatus.running || fixStatus.running) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  if (store.nameRequired) {
    return <CheckupReservationFixWithNameAndConcent store={store} />;
  }

  return <ReservationFixWithConcent store={store} />;
});

type ChildProps = {
  store: CheckupReservationTemporallyStore;
};

const CheckupReservationFixWithNameAndConcent = observer(({ store }: ChildProps) => {
  const { push, encode } = useReservationId();
  const { setError } = useContext(ErrorDialogContext);
  const session = useContext(SessionStore.Context);
  const [isRegistering, setIsRegistering] = useState(false);

  const [doFix, status] = useTransaction(
    async () => {
      try {
        const res = await store.fix();
        window.document.body.dispatchEvent(new Event(`clinicten.${GtmClasses.gtm.conversion.withSignIn}`));
        if (store.card?.id) {
          const fullPath = `${window.location.origin}${Routes.Paths.Payjp3dSecureFinish}?reservationId=${
            res ? encode(res) : ''
          }`;
          const payjp3dSecureStore = new Payjp3dSecureStore(store.card.id, fullPath, Routes.Paths.ReservationSuccess);
          await payjp3dSecureStore.redirect3dSecure();
        } else {
          res && push(Routes.Paths.ReservationSuccess, res);
        }
      } finally {
        setIsRegistering(false);
      }
    },
    e => {
      setError({
        header: '予約に失敗しました',
        message: e.message,
        onOk: () => {
          session.logout('/');
          setError();
        },
      });
    },
  );

  const [concent, setConcent] = useState(false);
  const [concentMedical, setConcentMedical] = useState(false);

  const onSubmit = useRef<() => void>(doFix);

  return (
    <Wrapper>
      <Modal visible={isRegistering}>
        <Card>予約情報を登録しています...</Card>
      </Modal>
      <Caption type="header" underline align="center">
        予約情報の登録
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <FormLabel required>電話番号</FormLabel>
          <PhoneInput
            color="white"
            value={store.phone}
            onChange={v => store.setPhone(v)}
            error={store.errors.some(e => e.field === 'phone')}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <CardInputLayout
            required
            callback={async t => {
              store.setCard({
                brand: t.brand,
                id: t.payjpID,
                expAt: t.expAt,
                fourDigit: t.fourDigit,
              });
              return doFix();
            }}
            onSubmit={onSubmit}
            onStatusChange={s => store.cardStatus.setCardTransactionStatus(s)}
            onPreCardValidateChange={v => store.cardStatus.setPreCardValidate(v)}
            phone={store.phone}
          />
        </FormItem>
        <FormItem>
          <FormLabel>メールアドレス(任意)</FormLabel>
          <TextField type="email" color="white" value={store.mail} onChange={v => store.setMail(v)} />
          <Caption type="small">予約時にGoogleカレンダー招待をお送りします。</Caption>
        </FormItem>
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <FormItem>
          <Checkbox
            label={`${store.clinic?.name}への医療情報の提供に同意する`}
            checked={concentMedical}
            onChange={v => setConcentMedical(v.target.checked)}
          />
          <div>
            <a
              style={{ textDecoration: 'underline' }}
              href={store.clinic?.termsUrl || '/'}
              target="_blank"
              rel="noreferrer"
            >
              {store.clinic?.name}の利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <div className={errorStyle}>{store.cardStatus.cardTransactionStatus?.error}</div>
        <Button
          type="submit"
          block
          onClick={() => {
            setIsRegistering(true);
            onSubmit.current();
          }}
          disabled={
            status.running ||
            !store.valid ||
            !concent ||
            !concentMedical ||
            store.cardStatus.cardTransactionStatus?.running ||
            store.cardStatus.hasPreCardError
          }
          className={`${GtmClasses.gtm.conversion.withNewAccount} ${store.departmentId} ${store.treatmentKindId}`}
        >
          登録して予約を確定
        </Button>
      </FormLayout>
    </Wrapper>
  );
});

const ReservationFixWithConcent = observer(({ store }: ChildProps) => {
  const { push } = useReservationId();

  const [doFix, status] = useTransaction(async () => {
    const res = await store.fix();
    res && push(Routes.Paths.ReservationSuccess, res);
  });

  const [concentMedical, setConcentMedical] = useState(false);

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        規約の確認
      </Caption>
      <FormLayout>
        <FormItem>
          <Checkbox
            label={`${store.clinic?.name}への医療情報の提供に同意する`}
            checked={concentMedical}
            onChange={v => setConcentMedical(v.target.checked)}
          />
          <div>
            <a
              style={{ textDecoration: 'underline' }}
              href={store.clinic?.termsUrl || '/'}
              target="_blank"
              rel="noreferrer"
            >
              {store.clinic?.name}の利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <Button
          type="submit"
          block
          onClick={() => doFix()}
          disabled={status.running || !concentMedical}
          className={`${GtmClasses.gtm.conversion.withConcent} C`}
        >
          予約を確定
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
