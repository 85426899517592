import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '../../../../../components/Button';
import { Caption } from '../../../../../components/Caption';
import { Card } from '../../../../../components/Card';
import { Checkbox } from '../../../../../components/Checkbox';
import { FormItem, FormLabel, FormLayout } from '../../../../../components/Form';
import { Modal } from '../../../../../components/Modal';
import { PhoneInput } from '../../../../../components/PhoneInput';
import { TextField } from '../../../../../components/TextField';
import { Wrapper } from '../../../../../components/Wrapper';
import { GtmClasses } from '../../../../../constants/AnalyticsTags';
import { useContext } from '../../../../../hooks/useContext';
import { SessionStore } from '../../../../../stores/SessionStore';
import { AddressInput } from '../../../AccountDetail/AccountEdit/components/AddressInput';
import { InsuranceCardInput } from '../../../AccountDetail/AccountEdit/components/InsuranceCardInput';
import { CardInputLayout } from '../../../AccountDetail/components/CardInputLayout';
import { errorStyle } from '../components/AccountsNameInput';
import { NameInput } from '../components/NameInput';
import { useActions } from './hooks/useActions';
import { ReservationTemporallyStore } from './stores/ReservationTemporallyStore';

export const AccountsNewNameInputFix = observer(() => {
  const session = useContext(SessionStore.Context);
  const store = useMemo(() => new ReservationTemporallyStore(), []);
  const { doFix, fixStatus, load, loadStatus } = useActions(store, session);

  useEffect(() => {
    if (!session.authStateKnown) {
      return;
    }
    const user = session.user?.userEntity;
    store.setNewComer(!user);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.authStateKnown]);

  if (!session.authStateKnown || loadStatus.running) {
    return (
      <Modal visible>
        <Card>ユーザー情報を取得しています...</Card>
      </Modal>
    );
  }

  if (store.newComer) {
    return <ReservationFixNewComer store={store} onCommit={() => doFix()} disabled={fixStatus.running} />;
  }

  return <ReservationFixRevisit store={store} onCommit={() => doFix()} disabled={fixStatus.running} />;
});

type ChildProps = {
  store: ReservationTemporallyStore;
  onCommit: () => void;
  disabled?: boolean;
};

const ReservationFixNewComer = observer(({ store, onCommit, disabled }: ChildProps) => {
  const [concent, setConcent] = useState(false);
  const [concentMedical, setConcentMedical] = useState(false);
  const onSubmit = useRef<() => void>(onCommit);

  return (
    <Wrapper>
      <Modal visible={!!store.cardStatus.cardTransactionStatus?.running || (disabled ?? false)}>
        <Card>予約情報を登録しています...</Card>
      </Modal>
      <Caption type="header" underline align="center">
        予約情報の登録
      </Caption>
      <FormLayout>
        <FormItem>
          <NameInput
            value={store.name || { sei: '', mei: '' }}
            onChange={v => store.setName(v)}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <FormLabel required>電話番号</FormLabel>
          <PhoneInput
            color="white"
            value={store.phone}
            onChange={v => store.setPhone(v)}
            error={store.errors.some(e => e.field === 'phone')}
            errors={store.errors}
          />
        </FormItem>
        <FormItem>
          <CardInputLayout
            callback={async t => {
              store.setCard({
                brand: t.brand,
                cardToken: t.payjpID,
                expAt: t.expAt,
                fourDigit: t.fourDigit,
              });
              return onCommit();
            }}
            required
            onSubmit={onSubmit}
            onStatusChange={s => store.cardStatus.setCardTransactionStatus(s)}
            onPreCardValidateChange={v => store.cardStatus.setPreCardValidate(v)}
            phone={store.phone}
          />
        </FormItem>
        <FormItem>
          <FormLabel>メールアドレス(任意)</FormLabel>
          <TextField type="email" color="white" value={store.mail} onChange={v => store.setMail(v)} />
          <Caption type="small">予約時にGoogleカレンダー招待をお送りします。</Caption>
        </FormItem>
        {store.fever && (
          <>
            <FormItem>
              <FormLabel required>保険証画像</FormLabel>
              <InsuranceCardInput store={store.insuranceCards} />
            </FormItem>
            <FormItem>
              <FormLabel>住所</FormLabel>
              <AddressInput value={store.address} onChange={a => store.setAddress(a)} />
            </FormItem>
            <FormItem>
              <FormLabel>職業</FormLabel>
              <TextField type="text" color="white" value={store.profession} onChange={v => store.setProfession(v)} />
            </FormItem>
          </>
        )}
        <FormItem>
          <Checkbox label="TENの利用規約に同意する" checked={concent} onChange={v => setConcent(v.target.checked)} />
          <div>
            <a style={{ textDecoration: 'underline' }} href="/terms" target="_blank">
              利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <FormItem>
          <Checkbox
            label={`${store.clinic?.name}への医療情報の提供に同意する`}
            checked={concentMedical}
            onChange={v => setConcentMedical(v.target.checked)}
          />
          <div>
            <a
              style={{ textDecoration: 'underline' }}
              href={store.clinic?.termsUrl || '/'}
              target="_blank"
              rel="noreferrer"
            >
              {store.clinic?.name}の利用規約を確認(別タブが開きます)
            </a>
          </div>
        </FormItem>
        <Button
          type="submit"
          block
          onClick={() => onSubmit.current()}
          disabled={
            disabled ||
            !store.valid ||
            !concent ||
            !concentMedical ||
            store.cardStatus.cardTransactionStatus?.running ||
            store.cardStatus.hasPreCardError
          }
          className={`${GtmClasses.gtm.conversion.withNewAccount} ${store.department} ${store.treatmentKindId}`}
        >
          登録して予約を確定
        </Button>
        <div className={errorStyle}>{store.cardStatus.cardTransactionStatus?.error}</div>
      </FormLayout>
    </Wrapper>
  );
});

const ReservationFixRevisit = observer(({ store, onCommit, disabled }: ChildProps) => {
  const [concentMedical, setConcentMedical] = useState(false);

  const concentValid = useMemo(() => !store.concentRequired || concentMedical, [store.concentRequired, concentMedical]);

  return (
    <Wrapper>
      <Caption type="header" underline align="center">
        予約を確定する
      </Caption>
      <FormLayout>
        {store.concentRequired && (
          <FormItem>
            <Checkbox
              label={`${store.clinic?.name}への医療情報の提供に同意する`}
              checked={concentMedical}
              onChange={v => setConcentMedical(v.target.checked)}
            />
            <div>
              <a
                style={{ textDecoration: 'underline' }}
                href={store.clinic?.termsUrl || '/'}
                target="_blank"
                rel="noreferrer"
              >
                {store.clinic?.name}の利用規約を確認(別タブが開きます)
              </a>
            </div>
          </FormItem>
        )}
        {store.fever && (
          <FormItem>
            <FormLabel required>電話番号</FormLabel>
            <PhoneInput
              color="white"
              value={store.phone}
              onChange={v => store.setPhone(v)}
              error={store.errors.some(e => e.field === 'phone')}
              errors={store.errors}
            />
          </FormItem>
        )}
        <Button
          type="submit"
          block
          onClick={() => onCommit()}
          disabled={disabled || !store.valid || !concentValid}
          className={`${GtmClasses.gtm.conversion.withConcent} ${store.department} ${store.treatmentKindId}`}
        >
          予約を確定
        </Button>
      </FormLayout>
    </Wrapper>
  );
});
