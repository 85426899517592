import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { GQL } from '../../../../../gql/client';
import {
  CreateCardInput,
  CreateUserInput,
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from '../../../../../gql/gql-types';
import { registerUser } from '../../../../../gql/operations/registerUser';
import { Card } from '../../../../domains/customer/Card';
import { CreditCardStore } from '../../store/CreditCardStore';

export type Name = {
  sei: string;
  mei: string;
};

/**
 * ユーザーの登録処理を司る
 */
export class ProfileStore {
  constructor() {
    makeAutoObservable(this, {
      name: observable,
      setName: action,
      register: action,
      valid: observable,
      errors: observable,
      mail: observable,
      setMail: action,
      card: observable,
      setCard: action,
      phone: observable,
      setPhone: action,
    });
  }

  public valid = false;
  public name?: Name = undefined;
  public mail?: string = undefined;
  public phone?: string = undefined;
  public card?: Card = undefined;

  public errors: ErrorInfo[] = [];

  public setName(v: Name) {
    this.name = v;
    this.checkNameValidity();
  }

  public setMail(mail: string) {
    this.mail = mail;
  }

  public setCard(card?: Card) {
    this.card = card;
  }

  public setPhone(phone: string) {
    this.phone = phone;
    this.checkPhoneValidity();
  }

  private checkNameValidity() {
    runInAction(() => {
      // 名前関連のエラーをクリア
      this.errors = this.errors.filter(error => error.field !== 'sei' && error.field !== 'mei');

      if (!this.name) {
        this.errors = [...this.errors, { field: 'sei', error: '氏名を入力してください。' }];
        this.valid = false;
        return;
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.sei)) {
        this.errors = [...this.errors, { field: 'sei', error: '氏名は全角カタカナで入力してください。' }];
      }
      if (!/^([ァ-ン]|ー)+$/.test(this.name.mei)) {
        this.errors = [...this.errors, { field: 'mei', error: '氏名は全角カタカナで入力してください。' }];
      }

      // エラーが残っていればvalidをfalseに設定
      this.valid = this.errors.length === 0;
    });
  }

  private checkPhoneValidity() {
    runInAction(() => {
      // 電話番号関連のエラーをクリア
      this.errors = this.errors.filter(error => error.field !== 'phone');

      if (!this.phone) {
        this.errors = [...this.errors, { field: 'phone', error: '電話番号を入力してください。' }];
        this.valid = false;
        return;
      }

      if (this.phone.startsWith('+81')) {
        const localNumber = this.phone.substring(3);
        if (!/^\d{9,10}$/.test(localNumber)) {
          this.errors = [...this.errors, { field: 'phone', error: '10桁または11桁で入力してください。' }];
        }
      } else {
        if (!/^\+\d{2,}\d{7,11}$/.test(this.phone)) {
          this.errors = [...this.errors, { field: 'phone', error: '電話番号の桁数を確認してください。' }];
        }
      }
      // エラーが残っていればvalidをfalseに設定
      this.valid = this.errors.length === 0;
    });
  }

  public cardStatus: CreditCardStore = new CreditCardStore();

  /**
   * 事前に指定されたユーザー登録処理を行う。
   */
  public async register() {
    const user: CreateUserInput = {
      id: '_',
      // eslint-disable-next-line no-irregular-whitespace
      name: `${this.name?.sei}　${this.name?.mei}` || '',
      mail: this.mail,
      confirmed: [],
      sei: this.name?.sei,
      mei: this.name?.mei,
      phone: this.phone,
      insuranceCards: [],
    };

    const card: CreateCardInput | undefined = this.card
      ? {
          id: '_',
          cardCustomerId: '_',
          fourDigit: this.card.fourDigit || '',
          brand: this.card.brand || '',
          payjpID: this.card.id || '',
          expAt: this.card.expAt || '',
        }
      : undefined;

    return GQL.query<RegisterUserMutationVariables, RegisterUserMutation>(registerUser, {
      input: {
        user,
        card,
      },
    });
  }
}

export type ErrorInfo = {
  field: string;
  error: string;
};
