import { css } from 'emotion';
import React, { ReactNode } from 'react';

import { QuestionTypeFragment } from '../../../../../../../gql/gql-types';
import { InterviewStomachMap } from '../../../../../../components/InterviewForm/StomachMap';
import { InterviewUploadInput } from '../../../../../../components/InterviewForm/Upload';
import { Color, FontSize } from '../../../../../../constants/Style';
import { gutter } from '../../../../../../helpers/Style';

type Props = {
  children: ReactNode;
};
export const ConfirmationFormArea = ({ children }: Props) => <div>{children}</div>;

export const ConfirmationFormGroup = ({ children }: Props) => <div className={confirmationGroupStyle}>{children}</div>;

const confirmationGroupStyle = css`
  margin-bottom: ${gutter(10)};
`;

const confirmationGroupTitleStyle = css`
  font-size: ${FontSize.Medium};
  font-style: normal;
  font-weight: bold;
`;

const confirmationGroupTitleContainerStyle = css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: ${gutter(2)};
  margin-bottom: ${gutter(2)};
  border-bottom: 2px solid ${Color.GrayscaleLight};
`;

type TitleProps = Props & { editable?: boolean; disabled?: boolean; onClickEdit?: () => void };
export const ConfirmationGroupTitle = ({ children, editable, disabled, onClickEdit }: TitleProps) => (
  <div className={confirmationGroupTitleContainerStyle}>
    <div className={confirmationGroupTitleStyle}>{children}</div>
    {editable ? (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        onClick={e => {
          if (disabled) {
            e.preventDefault();
            return;
          }
          onClickEdit?.();
        }}
        href="#"
        style={disabled ? { color: Color.GrayscaleLight, pointerEvents: 'none', textDecoration: 'none' } : undefined}
      >
        編集する
      </a>
    ) : (
      <></>
    )}
  </div>
);

const withDashedBorderBottom = css`
  padding: ${gutter(4)};
  border-bottom: 2px dashed ${Color.GrayscaleLight};
`;
export const ConfirmationFormItem = ({ children }: Props) => <div className={withDashedBorderBottom}>{children}</div>;

const graySmallLabel = css`
  font-size: ${FontSize.Small};
  color: ${Color.GrayscaleNeutral};
`;
export const ConfirmationFormLabel = ({ children }: Props) => <div className={graySmallLabel}>{children}</div>;

type ValueProps = {
  question: QuestionTypeFragment;
};

export const ConfirmationFormItemValue = ({ question }: ValueProps) => {
  const { options, answer: answers } = question;
  if (options?.[0]?.type === 'stomachMap') {
    return (
      <InterviewStomachMap
        disabled
        def={{}}
        onChange={() => {}}
        value={
          answers?.map(a => ({
            id: String(a?.option?.id || 0),
            label: a?.option?.label || '',
            value: a?.answer || '',
          })) || []
        }
      />
    );
  }
  if (question.additional_type === 'upload') {
    return (
      <InterviewUploadInput
        disabled
        def={{}}
        onChange={() => {}}
        value={
          answers?.map(a => ({
            id: String(a?.option?.id || 0),
            label: a?.option?.label || '',
            value: a?.answer || '',
          })) || []
        }
      />
    );
  }
  return <>{answers?.map(a => a?.answer).join('、')}</>;
};
