import 'react-phone-number-input/style.css';

import { css, cx } from 'emotion';

// ライブラリのデフォルトスタイルをオーバーライドして枠線を消す
const overrideStyles = css`
  .PhoneInputInput {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .PhoneInput {
    border: none !important;
    box-shadow: none !important;
  }
`;
import React, { useMemo, useState } from 'react';
import { default as Phone } from 'react-phone-number-input';

import { ErrorMessage } from '../../components/ErrorMessage';
import { BorderRadius, Color, Duration, FontSize } from '../../constants/Style';
import { gutter } from '../../helpers/Style';
import { ErrorInfo } from '../../pages/Accounts/AccountsNew/stores/ProfileStore';

type Props = Partial<{
  value: string;
  disabled: boolean;
  required: boolean;
  onChange: (value: string) => void;
  color: 'white' | 'grey';
  onFocusout?: () => void;
  error: boolean;
  analyticsClassName: string;
  errors?: ErrorInfo[];
}>;

export const PhoneInput = ({
  value,
  disabled,
  required,
  onChange,
  color = 'grey',
  onFocusout,
  error = false,
  analyticsClassName,
  errors = [],
}: Props) => {
  const [focused, setFocused] = useState(false);

  const style = useMemo(
    () =>
      cx(
        baseStyle,
        css`
          background-color: ${color === 'grey' ? Color.TextureInput : Color.GrayscaleWhite};
        `,
        error &&
          css`
            box-shadow: 0 0 0 2px ${Color.FunctionalRedNeutral};
          `,
      ),
    [color, error],
  );

  const handleBlur = () => {
    setFocused(true);
    if (onFocusout) {
      onFocusout();
    }
  };

  return (
    <div className={overrideStyles}>
      <Phone
        displayInitialValueAsLocalNumber
        className={`${style} ${analyticsClassName || ''}`}
        defaultCountry={'JP'}
        placeholder="090-1234-5678"
        disabled={disabled}
        value={value}
        required={required}
        onChange={value => onChange?.(value ?? '')}
        onBlur={handleBlur}
      />
      {focused && (
        <div>
          {errors
            .filter(e => e.field === 'phone')
            .map(e => (
              <ErrorMessage key={e.error}>{e.error}</ErrorMessage>
            ))}
        </div>
      )}
    </div>
  );
};

const baseStyle = css`
  display: block;
  width: 100%;
  padding: ${gutter(4)};
  font-size: ${FontSize.Medium};
  line-height: 1;
  background-color: ${Color.TextureInput};
  border: none;
  border-radius: ${BorderRadius.Small};
  outline: none;
  box-shadow: none;
  transition: box-shadow ${Duration.Fade};
  appearance: none;

  &::placeholder {
    color: ${Color.GrayscaleNeutral};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${Color.GrayscaleDarker};
  }

  &:disabled {
    color: ${Color.GrayscaleNeutral};
    cursor: not-allowed;
  }
`;
