import { ErrorDialogContext } from '../../../../../../components/ErrorDialog';
import { Routes } from '../../../../../../constants/Routes';
import { useContext } from '../../../../../../hooks/useContext';
import { useReservationId } from '../../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../../hooks/useTransaction';
import { SessionStore } from '../../../../../../stores/SessionStore';
import { Payjp3dSecureStore } from '../../../../store/Payjp3dSecureStore';
import { ReservationTemporallyStore } from '../stores/ReservationTemporallyStore';

export const useActions = (store: ReservationTemporallyStore, session: SessionStore) => {
  const { reservationId, push, encode } = useReservationId();
  const { setError } = useContext(ErrorDialogContext);
  const handleError = (message: string) => {
    setError({
      header: '予約に失敗しました',
      message,
      onOk: () => {
        session.logout('/');
        setError();
      },
    });
  };

  const [load, loadStatus] = useTransaction(
    () =>
      store.fetch(reservationId).then(() => {
        if (store.fixable) {
          doFix();
        }
      }),
    e => {
      handleError(e.message);
    },
  );

  const [doFix, fixStatus] = useTransaction(
    () =>
      store.fix().then(async res => {
        if (store.card?.cardToken) {
          const fullPath = `${window.location.origin}${Routes.Paths.Payjp3dSecureFinish}?reservationId=${
            res ? encode(res) : ''
          }`;
          const payjp3dSecureStore = new Payjp3dSecureStore(
            store.card.cardToken,
            fullPath,
            Routes.Paths.ReservationSuccess,
          );
          await payjp3dSecureStore.redirect3dSecure();
        } else {
          res && push(Routes.Paths.ReservationSuccess, res);
        }
      }),
    e => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gqlError: { errors?: { message: string }[] } = e as any;
      handleError(gqlError.errors?.[0]?.message || e.message);
    },
  );

  return {
    doFix,
    fixStatus,
    load,
    loadStatus,
  };
};
