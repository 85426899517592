import gql from 'graphql-tag';

export const finishPayjp3dSecure = gql`
  mutation finishPayjp3dSecure($input: FinishPayjp3dSecureInput!) {
    finishPayjp3dSecure(input: $input) {
      payjpID
      fourDigit
      brand
      expAt
      threeDSecureStatus
    }
  }
`;
