import { GQL } from '../../../../gql/client';
import {
  GetPayjp3dSecureCallbackUrlQuery,
  GetPayjp3dSecureCallbackUrlQueryVariables,
  GetPayjpPublicKeyQuery,
  GetPayjpPublicKeyQueryVariables,
} from '../../../../gql/gql-types';
import { getPayjp3dSecureCallbackUrl } from '../../../../gql/operations/getPayjp3dSecureCallbackUrl';
import { getPayjpPublicKey } from '../../../../gql/operations/getPayjpPublicKey';

export class Payjp3dSecureStore {
  constructor(public payjpId: string, public url: string, public backTo: string = '') {
    this.payjpId = payjpId;
    this.url = url;
    this.backTo = backTo;
  }

  public async redirect3dSecure() {
    const publicKey = await this.getPublicKey();
    const jwsurl = await this.getCallbackUrl();
    // 3Dセキュア開始エンドポイントへエンドユーザーを誘導
    window.location.href = `https://api.pay.jp/v1/tds/${this.payjpId}/start?publickey=${publicKey}&back_url=${jwsurl}`;
  }

  private async getPublicKey() {
    const pk = await GQL.query<GetPayjpPublicKeyQueryVariables, GetPayjpPublicKeyQuery>(getPayjpPublicKey, {});
    return pk.getPayjpPublicKey?.publicKey ?? '';
  }

  private async getCallbackUrl() {
    const separator = this.url.includes('?') ? '&' : '?';
    const callbackUrl = this.backTo ? this.url + separator + 'backTo=' + this.backTo : this.url;
    const pk = await GQL.query<GetPayjp3dSecureCallbackUrlQueryVariables, GetPayjp3dSecureCallbackUrlQuery>(
      getPayjp3dSecureCallbackUrl,
      { input: { url: callbackUrl, payjpID: this.payjpId } },
    );
    return pk.getPayjp3dSecureCallbackUrl?.url ?? '';
  }
}
