import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';

import { Button } from '../../../../../components/Button';
import { ButtonGroup } from '../../../../../components/ButtonGroup';
import { Caption, CaptionGroup } from '../../../../../components/Caption';
import { DocumentTitle } from '../../../../../components/DocumentTitle';
import { FormItem, FormLabel, FormLayout, FormSubLabel } from '../../../../../components/Form';
import { useToast } from '../../../../../components/Toast';
import { Wrapper } from '../../../../../components/Wrapper';
import { GtmClasses } from '../../../../../constants/AnalyticsTags';
import { Routes } from '../../../../../constants/Routes';
import { Color } from '../../../../../constants/Style';
import { useReservationId } from '../../../../../hooks/useReservationId';
import { useTransaction } from '../../../../../hooks/useTransaction';
import { ConfirmationFormLabel } from '../../../../../pages/Interviews/InterviewsNew/InterviewsConfirmation/components/ConfirmationForm';
import { Conditional } from '../..';
import { AccountDetailStore } from '../../stores/AccountDetailStore';
import { InsuranceCardInput } from '../components/InsuranceCardInput';
import { STEP } from '../index';

type Props = {
  store: AccountDetailStore;
  setStep: (step: string) => void;
};

export const ConfirmPage = observer(({ store, setStep }: Props) => {
  const { addToast } = useToast();
  const { push, reservationId } = useReservationId();

  const [updateFirstReserve, updateFirstReserveStatus] = useTransaction(async () => {
    if (!store.id) return;
    await store.updateFirstReserveProfile();
    addToast({ message: 'プロフィールを更新しました。', icon: 'check-circle' });
    push(Routes.Paths.ReservationFirstSuccess, reservationId);
  });

  return (
    <Wrapper>
      <DocumentTitle title="患者プロフィール登録" />
      <Caption type="header" underline align="center">
        登録情報の確認
      </Caption>
      <CaptionGroup>
        <Caption type="body">
          感染症対策及び待ち時間短縮のため、初めてご来院の方全員にご登録をお願いしています。
        </Caption>
      </CaptionGroup>
      <FormLayout>
        <FormItem>
          <FormLabel>保険証</FormLabel>
          <div className={separateBorderStyle} />
          <FormSubLabel>表面</FormSubLabel>
          {store.edit && <InsuranceCardInput store={store.edit.insuranceCards} confirm />}
        </FormItem>
        <FormItem>
          <FormLabel>プロフィール</FormLabel>
          <div className={separateBorderStyle} />
          <div className={formItemStyle}>
            <ConfirmationFormLabel>電話番号</ConfirmationFormLabel>
            <Conditional condition={store.edit?.formattedPhone()} />
          </div>
          <div className={formItemStyle}>
            <ConfirmationFormLabel>住所</ConfirmationFormLabel>
            <Conditional condition={store.edit?.address}>
              <div>{store.edit?.address?.zipCode}</div>
              <div>
                {store.edit?.address?.pref}
                {store.edit?.address?.addressLine1} {store.edit?.address?.addressLine2}
              </div>
            </Conditional>
          </div>
        </FormItem>
      </FormLayout>
      <div className={buttonGroupStyle}>
        <ButtonGroup gutter={10}>
          <Button
            block
            disabled={!!updateFirstReserveStatus.running || !store.edit?.valid}
            onClick={() => updateFirstReserve()}
            className={GtmClasses.gtm.registration.patientInformation.finish}
          >
            この内容で登録する
          </Button>
          <Button
            ghost
            disabled={!!updateFirstReserveStatus.running || !store.edit?.valid}
            onClick={() => setStep(STEP.REGISTER)}
          >
            修正する
          </Button>
        </ButtonGroup>
      </div>
    </Wrapper>
  );
});

const separateBorderStyle = css`
  margin-top: -6px;
  margin-bottom: 16px;
  border-bottom: 2px solid ${Color.GrayscaleLight};
`;

const formItemStyle = css`
  margin-bottom: 28px;
`;

const buttonGroupStyle = css`
  margin-top: 16px;
`;
