import { GQL } from '../../../../../gql/client';
import { FinishPayjp3dSecureMutation, FinishPayjp3dSecureMutationVariables } from '../../../../../gql/gql-types';
import { finishPayjp3dSecure } from '../../../../../gql/operations/finishPayjp3dSecure';

export class PayjpStore {
  public static async finish3dSecure(payjpID: string): Promise<FinishPayjp3dSecureMutation> {
    return GQL.query<FinishPayjp3dSecureMutationVariables, FinishPayjp3dSecureMutation>(finishPayjp3dSecure, {
      input: { payjpID: payjpID },
    });
  }
}
