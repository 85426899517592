import React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Card } from '../../../components/Card';
import { Modal } from '../../../components/Modal';
import { useToast } from '../../../components/Toast';
import { Routes } from '../../../constants/Routes';
import { useReservationId } from '../../../hooks/useReservationId';
import { PayjpStore } from './stores/payjpStore';

export const Payjp3dSecureFinishPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToast();
  const { push, reservationId, decode } = useReservationId();

  useEffect(() => {
    // URLクエリパラメータを取得
    const queryParams = new URLSearchParams(location.search);
    const payjpID = queryParams.get('payjpID');
    const waitingId = decode(queryParams.get('waitingId') ?? '');
    const backTo = queryParams.get('backTo') ?? Routes.BasePath;
    const action = queryParams.get('action');

    if (!payjpID) {
      throw new Error('PAY.JP IDが指定されていません');
    }

    (async () => {
      try {
        // PAY.JP APIに3Dセキュア認証完了リクエストを送信
        await PayjpStore.finish3dSecure(payjpID);
        // 成功時のリダイレクト
        // TODO： 分岐をリファクタする
        if (waitingId) {
          // waitingIdがある場合は待機成功ページに遷移
          push(Routes.Paths.WaitingSuccess, waitingId);
        } else if (reservationId) {
          // reservationIdがある場合は予約成功ページに遷移
          push(Routes.Paths.ReservationSuccess, reservationId);
        } else if (action === 'startCheckup') {
          history.push({ pathname: Routes.Paths.CheckupReservationNew, search: window.location.search });
        } else {
          // どちらもない場合は従来の遷移先に移動
          const url = queryParams.get('url');
          history.push(url || backTo);
        }
      } catch (error) {
        console.error('エラー:', { error });
        addToast({ message: 'クレジットカードの認証でエラーが発生しました。', theme: 'error' });
        history.push(Routes.Paths.Account);
      }
    })();
  }, [location.search, history, addToast, reservationId, push]);

  return (
    <Modal visible>
      <Card>クレジットカードの3Dセキュア認証の完了処理をしています...</Card>
    </Modal>
  );
};
